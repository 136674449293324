<template>
    <v-card v-if="match" class="py-5 elevation-1 rounded-lg" height="100%">
        <v-row v-if="!$vuetify.breakpoint.mobile" justify="center" align="center" class="px-5" @click="$router.push({ name: 'Match', params: { id: match.id } })">
            <v-col cols="2" align="center">
                <span v-if="!match.postponed">
                    <v-icon
                        color="orange"
                    >
                        mdi-calendar-outline
                    </v-icon>
                    {{ getDate(match.matchDatetime) }}
                </span>
                <span v-else>
                    <v-icon
                        color="red"
                    >
                        mdi-update
                    </v-icon>
                    Αναβληθέν Παιχνίδι
                </span>
                <div class="font-weight-thin">
                    {{ `${match.league? match.league.title : 'Κύπελο'}, Round ${match.round}, ` + (match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs'))) }}
                </div>
            </v-col>
            <v-col cols="2" align="end" class="clickable" @click.stop="goToTeam(match.team1)">
                {{ match.team1.name }}
            </v-col>
            <v-col cols="1" align="center" class="clickable" @click.stop="goToTeam(match.team1)">
                <v-img
                    v-if="match.team1.logo"
                    :src="`${assetsUrl}/${match.team1.logo}`"
                    height="50px"
                    contain
                />
                <v-icon v-else style="font-size: 50px; display: block;" class="orange--text"> mdi-basketball </v-icon>
            </v-col>
            <v-col v-if="match.pending || match.postponed" cols="2" lg="1" align="center" class="grey--text lighten-1 font-weight-bold px-0 rounded">
                <v-row no-guitters>
                    <v-col cols="4" class="px-0 mx-0">
                        <v-img
                            :src="require('@/assets/jersey-orange.svg')"
                            height="30px"
                            contain
                        />
                    </v-col>
                    <v-col cols="4" class="px-0 mx-0">
                        <span class="mx-1">vs</span>
                    </v-col>
                    <v-col cols="4" class="px-0 mx-0">
                        <v-img
                            :src="require('@/assets/jersey-black.png')"
                            height="30px"
                            contain
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else cols="1" align="center" class="px-0 py-5">
                <v-row>
                    <v-col cols="6" class="font-weight-bold left-rounded" :class="match.scoreTeam1 > match.scoreTeam2 ? 'winning-score custom-orange' : 'losing-score-left'">
                        <span>{{ match.scoreTeam1 }}</span>
                    </v-col>
                    <v-col cols="6" class="font-weight-bold right-rounded" :class="match.scoreTeam2 > match.scoreTeam1 ? 'winning-score custom-orange' : 'losing-score-right'">
                        <span>{{ match.scoreTeam2 }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1" align="center" class="clickable" @click,stop="goToTeam(match.team2)">
                <v-img
                    v-if="match.team2.logo"
                    :src="`${assetsUrl}/${match.team2.logo}`"
                    height="50px"
                    contain
                />
                <v-icon v-else style="font-size: 50px; display: block;" class="orange--text"> mdi-basketball </v-icon>
            </v-col>
            <v-col cols="2" align="start" class="clickable" @click.stop="goToTeam(match.team2)">
                {{ match.team2.name }}
            </v-col>
            <v-col align="center" class="clickable" @click.stop="openMap(match.arena)">
                <v-icon
                    color="orange"
                >
                    mdi-basketball-hoop-outline
                </v-icon>
                {{ match.arena?.name }}
            </v-col>
            <v-col cols="1" align="end">
                <v-btn v-if="!match.pending && match.videoUrl" icon @click="openVideo">
                    <v-icon
                        color="red"
                        large
                    >
                        mdi-youtube
                    </v-icon>
                </v-btn>
                <v-btn v-if="!match.pending && !match.postponed" icon @click.stop="$router.push({ name: 'Match', params: { id: match.id } })">
                    <v-icon
                        color="orange"
                        large
                    >
                        mdi-arrow-right-thin-circle-outline
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-else class="px-0 mx-1" justify="center" align="center" @click="$router.push({ name: 'Match', params: { id: match.id } })">
            <v-col cols="12" align="center">
                <span v-if="!match.postponed">
                    <v-icon
                        color="orange"
                    >
                        mdi-calendar-outline
                    </v-icon>
                    {{ getDate(match.matchDatetime) }}
                </span>
                <span v-else>
                    <v-icon
                        color="red"
                    >
                        mdi-update
                    </v-icon>
                    Αναβληθέν παιχνίδι
                </span>
                <div class="font-weight-thin">
                    {{ `${match.league? match.league.title : 'Κύπελλο'}, Round ${match.round}, ` + (match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs'))) }}
                </div>
            </v-col>
            <v-col cols="4" align="center" class="clickable mx-0 px-0" @click.stop="goToTeam(match.team1)">
                <v-img
                    v-if="match.team1.logo"
                    :src="`${assetsUrl}/${match.team1.logo}`"
                    height="50px"
                    contain
                />
                <v-icon v-else style="font-size: 40px; display: block;" class="orange--text"> mdi-basketball </v-icon>
                {{ match.team1.name }}
            </v-col>
            <v-col v-if="match.pending || match.postponed" cols="3" align="center" class="grey--text lighten-1 font-weight-bold px-0 rounded">
                <v-row class="px-0 mx-0">
                    <v-col cols="4" class="px-0 mx-0">
                        <v-img
                            :src="require('@/assets/jersey-orange.svg')"
                            height="30px"
                            contain
                        />
                    </v-col>
                    <v-col cols="4" class="px-0 mx-0">
                        <span class="mx-1">vs</span>
                    </v-col>
                    <v-col cols="4" class="px-0 mx-0">
                        <v-img
                            :src="require('@/assets/jersey-black.png')"
                            height="30px"
                            contain
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else cols="3" align="center" class="px-0 py-5 mx-3">
                <v-row class="px-0 mx-0">
                    <v-col cols="6" class="font-weight-bold left-rounded px-0 mx-0" :class="match.scoreTeam1 > match.scoreTeam2 ? 'winning-score custom-orange' : 'losing-score-left'">
                        <span>{{ match.scoreTeam1 }}</span>
                    </v-col>
                    <v-col cols="6" class="font-weight-bold right-rounded px-0 mx-0" :class="match.scoreTeam2 > match.scoreTeam1 ? 'winning-score custom-orange' : 'losing-score-right'">
                        <span>{{ match.scoreTeam2 }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" align="center" class="clickable mx-0 px-0" @click.stop="goToTeam(match.team2)">
                <v-img
                    v-if="match.team2.logo"
                    :src="`${assetsUrl}/${match.team2.logo}`"
                    height="50px"
                    contain
                />
                <v-icon v-else style="font-size: 40px; display: block;" class="orange--text"> mdi-basketball </v-icon>
                {{ match.team2.name }}
            </v-col>
            <v-col cols="12" align="center" class="clickable" @click.stop="openMap(match.arena)">
                <v-icon
                    color="orange"
                >
                    mdi-basketball-hoop-outline
                </v-icon>
                {{ match.arena?.name }}
            </v-col>
            <v-col v-if="!match.pending && !match.postponed" cols="12" align="center">
                <v-btn v-if="match.videoUrl" icon @click.stop="openVideo">
                    <v-icon
                        color="red"
                        large
                    >
                        mdi-youtube
                    </v-icon>
                </v-btn>
                <v-btn icon @click.stop="$router.push({ name: 'Match', params: { id: match.id } })">
                    <v-icon
                        color="orange"
                        large
                    >
                        mdi-arrow-right-thin-circle-outline
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    export default {
        props: {
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        methods: {
            getDate(date) {
                return momentTimezone(date).tz('Europe/Athens').locale('el-GR').format('MMMM dddd D YYYY, HH:mm').toLocaleString('el-GR', 'full')
            },
            openMap(arena) {
                window.open(`https://www.google.com/maps/place/${arena.coordinates}`, '_self', 'noreferrer')
            },
            openVideo() {
                window.open(this.match.videoUrl, '_self', 'noreferrer')
            },
            goToTeam(team) {
                this.$router.push({ name: 'Team', params: { id: team.id } })
            }
        }
    }
</script>

<style scoped>

.left-rounded {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right-rounded {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.losing-score-right {
    outline: solid 2px orange;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.losing-score-left {
    outline: solid 2px #ef4444;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.winning-score {
    color: white;
}
</style>
